import { useSelectSlice } from '@common/redux/selectors/useSelectSlice';
import { picksSlice } from '@common/redux/slices/picks';
import { Layout } from '@kursk/components/Layout/Layout';
import { SeoMetaTitles } from "@kursk/components/seo/SeoMetaTitles";
import { Places } from './Places/Places';
import { Routes } from './Routes/Routes';
import { Events } from './Events/Events';
import { Overviews } from './Overviews/Overviews';
import { HeroBlock } from '@kursk/components/ui/HeroBlock/HeroBlock';
import { Socials } from './Socials/Socials';
import { Restaurants } from './Restaurants/Restaurants';
import { Hotels } from './Hotels/Hotels';
import styles from './IndexPage.module.scss';
import { SubscribeFooterForm } from "@kursk/components/ui/SubscribeFooterForm/SubscribeFooterForm";
import { TicketsBundles } from "./TicketsBundles/TicketsBundles";
import { Best } from "@kursk/components/pages/Home/Best/Best";
import { LdJson } from "@kursk/components/seo/LdJson";
import { regionContent } from "@kursk/content/regionContent";
import React from "react";
import { themeValue } from '@kursk/themes';
import { subscriptionFormSlice } from "@common/redux/slices/subscriptionForm.slice";
import { getConfig } from "@root/config/config";

export const IndexPage = ({ showAuth = false }) => {
  const {
    mainBanners, bestOverviews, bestEvents,
    bestRoutes, bestPlaces, bestRestaurants,
    bestHotels, best, bestTicketsBundles
  } = useSelectSlice(picksSlice);
  const subscription = useSelectSlice(subscriptionFormSlice);
  const blocksHash = {
    best: best && best.length > 0 && <section className={styles.section}>
      <Best entity={best.slice(0, 4)}/>
    </section>,
    events: bestEvents && bestEvents.length > 0 && <section className={styles.section}>
      <Events events={bestEvents}/>
    </section>,
    subscribe: (!getConfig().subscribeDisabled && !subscription.status) && (
      <section className={styles.section}>
        <SubscribeFooterForm/>
      </section>
    ),
    places: bestPlaces && bestPlaces.length > 0 && <section className={styles.section}>
      <Places places={bestPlaces}/>
    </section>,
    overviews: bestOverviews && bestOverviews.length > 0 && <section className={styles.section}>
      <Overviews overviews={bestOverviews}/>
    </section>,
    routes: bestRoutes && bestRoutes.length > 0 && <section className={styles.section}>
      <Routes routes={bestRoutes}/>
    </section>,
    socials: regionContent.socials.vk && regionContent.socials.telegram && (
      <section className={styles.section}>
        <Socials/>
      </section>
    ),
    restaurants: bestRestaurants && bestRestaurants.length > 0 && themeValue({
      primorsky: <></>,
      default: (
        <section className={styles.section}>
          <Restaurants restaurants={bestRestaurants}/>
        </section>
      )
    }),
    hotels: bestHotels && bestHotels.length > 0 && themeValue({
      primorsky: <></>,
      default: (
        <section className={styles.section}>
          <Hotels hotels={bestHotels}/>
        </section>
      )
    }),
    ticketsBundles: bestTicketsBundles && bestTicketsBundles.length > 0 && <section className={styles.section}>
      <TicketsBundles ticketsBundles={bestTicketsBundles}/>
    </section>
  }
  return (
    <Layout showAuth={showAuth} showLiveWidget showSubscribePopup={false} page="index">
      <LdJson
        type={'main'}
      />
      <SeoMetaTitles page="index"/>
      <div className={styles.main}>
        <HeroBlock banners={mainBanners} withMainBanner noBannerMode={regionContent.mainBanner.hide} page="index"/>
        <div>
          {regionContent.homePageBlocks.map((block) => blocksHash[block])}
        </div>
      </div>
    </Layout>
  )
}
